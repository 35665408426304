<template>
  <div>
    <div class="container" v-if="!this.registered">
      <form>
        <a href="cams.clubcamgirl.com"
          ><img class="logoCamLogin m-t-1 m-b-10" src="/img/camclub.png" alt=""
        /></a>
        <h1 class="m-b-3 mq-sz">Register</h1>
        <input
          class="m-b-2"
          id="email"
          type="email"
          placeholder="Email"
          v-model="email"
        />
        <input
          class="m-b-2"
          id="username"
          type="text"
          placeholder="Profile name"
          v-model="username"
        />
        <input
          class="m-b-2"
          id=""
          type="phone"
          placeholder="Phone Number"
          v-model="phone"
        />
        <div class="svg-input m-b-2">
          <input
            class="svginput"
            id="password"
            v-bind:type="typefield"
            placeholder="Password"
            v-model="password"
          />
          <eye v-if="!shut" v-on:click.native="changeType()"></eye>
          <eyeShut v-else v-on:click.native="changeType()"></eyeShut>
        </div>
        <div class="svg-input m-b-2">
          <input
            class="svginput"
            id="passwordC"
            v-bind:type="typefield"
            placeholder="Repeat Password"
            v-model="passwordC"
          />
          <eye v-if="!shut" v-on:click.native="changeType()"></eye>
          <eyeShut v-else v-on:click.native="changeType()"></eyeShut>
        </div>
        <vue-recaptcha
          sitekey="6LfMvzkcAAAAANVpH_9dTvBifWgUjc8iJoyp1WZY"
          :loadRecaptchaScript="true"
          ref="recaptcha"
          v-on:verify="verifyCaptcha"
        ></vue-recaptcha>
        <button v-if="!logginLoad" class="btn-login m-t-reg" v-on:click.stop.prevent="CreateModel">
          Create Account
        </button>
        <button v-else class="btn-login" disabled>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </button>
        <div class="login-links">
          <router-link to="/login"><a>Alredy Have an Account?</a></router-link>
        </div>
      </form>
    </div>
    <div
      class="container"
      style="background-color: rgba(245, 0, 173, 1)"
      v-if="this.registered"
    >
      <div class="forgotPasword">
        <h1>
          Succesfully
          <br />
          Register
        </h1>
        <span>
          Please check your email to confirm
          <br />
          your registration
        </span>
        <img class="fPass m-b-4" src="/img/emailConfirm.png" alt="" />
        <router-link to="/login"
          ><button class="btn-login">Done</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import Toastify from "toastify-js";
import eye from "../components/littles/eye.vue";
import EyeShut from "../components/littles/eyeShut.vue";
export default {
  metaInfo() {
    return {
      title: "Register Now",
      titleTemplate: "%s - Club Cam Girls!",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      link: [{ rel: "icon", href: "/favicon.png" }],
    };
  },
  data() {
    return {
      registered: false,
      email: null,
      username: null,
      password: null,
      passwordC: null,
      phone: null,
      captcha: false,
      typefield: "password",
      shut: false,
      logginLoad: false,
    };
  },
  components: { VueRecaptcha, eye, EyeShut },
  methods: {
    changeType() {
      if (this.typefield === "password") {
        this.typefield = "text";
        this.shut = true;
        return;
      }
      this.shut = false;
      this.typefield = "password";
    },
    async CreateModel() {
      this.logginLoad = true;
      if (!this.captcha) {
        this.logginLoad = false;
        Toastify({
          text: "Are you a robot? 🤖",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      if (!this.email) {
        this.logginLoad = false;
        Toastify({
          text: "No hay correo",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      if (!this.username) {
        this.logginLoad = false;
        Toastify({
          text: "No hay nombre de usuario",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      if (!this.password) {
        this.logginLoad = false;
        Toastify({
          text: "No hay password",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();

        return;
      }
      if (this.password !== this.passwordC) {
        this.logginLoad = false;
        Toastify({
          text: "confirma tu password",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      this.logginLoad = true;
      if (this.password === this.passwordC) {
        const model = await this.axios({
          method: "POST",
          baseURL: process.env.VUE_APP_BASE_URL,
          url: `/api/v1/models/`,
          data: {
            email: this.email,
            username: this.username,
            password: this.password,
          },
        });
        if (model.data.status === 200) {
          this.registered = true;
        }

        if (model.data.status === 400) {
          this.logginLoad = false;
          Toastify({
            text: "Username or Email don't available",
            duration: 3000,
            destination: "https://github.com/apvarun/toastify-js",
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "left", // `left`, `center` or `right`
            backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function () {}, // Callback after click
          }).showToast();
          return;
        }
        return;
      }
    },
    verifyCaptcha() {
      this.captcha = true;
    },
  },
};
</script>

<style>
.recaptcha {
  margin: 0.5%;
  max-width: 100% !important;
  max-height: 200px !important;
  left: 1%;
  right: 1%;
}
</style>